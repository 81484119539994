import request from '@/utils/request';
export function fetchMenuList() {
  return request({
    url: '/admin/sys-user/list-menu',
    method: 'get'
  });
}
export function list(query) {
  return request({
    url: 'admin/sys-user/listPage',
    method: 'get',
    params: query
  });
}
export function add(data) {
  return request({
    url: 'admin/sys-user/add',
    method: 'post',
    data: data
  });
}
export function update(data) {
  return request({
    url: 'admin/sys-user/update',
    method: 'post',
    data: data
  });
}
export function updatePwd(data) {
  return request({
    url: 'admin/sys-user/modifyPassword',
    method: 'post',
    data: data
  });
}
export function get(id) {
  return request({
    url: "admin/sys-user/detail?sysUserId=".concat(id),
    method: 'get'
  });
}
export function del(id) {
  return request({
    url: "admin/sys-user/delete?sysUserId=".concat(id),
    method: 'post'
  });
}
export function setRole(data) {
  return request({
    url: 'admin/sys-user/update-role',
    method: 'post',
    data: data
  });
}
export function getUserInfo() {
  return request({
    url: '/admin/sys-user/myInfo',
    method: 'get'
  });
}
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: data
  });
}
export function getAll() {
  return request({
    url: 'admin/sys-user/listAll',
    method: 'get'
  });
}
export function resetPassword(data) {
  return request({
    url: 'admin/sys-user/resetPassword',
    method: 'post',
    data: data
  });
}
export function clientUpload(data) {
  return request({
    url: 'api/upload/aliyun/pub/sts',
    method: 'post',
    data: data
  });
}
export function clearUserCache(data) {
  return request({
    url: 'admin/sys-user/clearUserCache',
    method: 'post',
    data: data
  });
}
export function clientUploadVideo(data) {
  return request({
    url: 'api/upload/aliyun/vod/createUploadVideo',
    method: 'post',
    data: data
  });
}
export function refreshUploadVideo(data) {
  return request({
    url: 'api/upload/aliyun/vod/refreshUploadVideo',
    method: 'post',
    data: data
  });
}
export function getPlayInfo(data) {
  return request({
    url: 'api/upload/aliyun/vod/getPlayInfo',
    method: 'post',
    data: data
  });
}