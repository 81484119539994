import _defineProperty from "/Users/wangjianbing/work/granwin/pet-admin-web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";
import "core-js/modules/web.dom.iterable";

var _mutations;

import * as types from "../mutation-types"; // initial state

var state = {
  isShow: true,
  activeNav: window.localStorage.getItem('activeNav') || '',
  navList: [],
  accountMenu: [],
  isCollapse: false
}; // getters

var getters = {
  isShow: function isShow(state) {
    return state.isShow;
  },
  activeNav: function activeNav(state) {
    return state.activeNav;
  },
  navList: function navList(state) {
    return state.navList;
  },
  accountMenu: function accountMenu(state) {
    return state.accountMenu;
  },
  isCollapse: function isCollapse(state) {
    return state.isCollapse;
  }
}; // actions

var actions = {
  setActiveNavId: function setActiveNavId(_ref, activeNav) {
    var commit = _ref.commit,
        state = _ref.state;
    commit(types.SET_ACTIVE_NAV, activeNav);
  },
  updateNavList: function updateNavList(_ref2, navList) {
    var commit = _ref2.commit,
        state = _ref2.state;
    commit(types.UPDATE_SIDE_NAV_LIST, navList);
  },
  updateAccountNavList: function updateAccountNavList(_ref3, navList) {
    var commit = _ref3.commit,
        state = _ref3.state;
    commit(types.UPDATE_ACCOUNT_NAV_LIST, navList);
  },
  setSideBarState: function setSideBarState(_ref4, status) {
    var commit = _ref4.commit,
        state = _ref4.state;
    commit(types.SET_SIDE_BAR_STATE, status);
  },
  setIsCollapse: function setIsCollapse(_ref5, status) {
    var commit = _ref5.commit,
        state = _ref5.state;
    commit(types.UPDATE_IS_COLLAPSE, status);
  },
  reset: function reset(_ref6) {
    var commit = _ref6.commit;
    commit(types.RESET_SIDEBAR_STATE);
  }
}; // mutations

var mutations = (_mutations = {}, _defineProperty(_mutations, types.SET_ACTIVE_NAV, function (state, nav) {
  window.localStorage.setItem('activeNav', nav);
  state.activeNav = nav;
}), _defineProperty(_mutations, types.UPDATE_ACCOUNT_NAV_LIST, function (state, navList) {
  // const menuList = [];
  // navList.forEach(nav => {
  //   if (nav.pid == 25) {
  //     menuList.push(nav)
  //   }
  // })
  state.accountMenu = menuList;
}), _defineProperty(_mutations, types.UPDATE_SIDE_NAV_LIST, function (state, navList) {
  var menuList = [];
  navList.forEach(function (nav) {
    if (nav.pid == 0) {
      nav.children = [];
      menuList.push(nav);
    }
  });
  navList.forEach(function (nav) {
    menuList.forEach(function (menu) {
      if (nav.pid == menu.sysMenuId) {
        menu.children.push(nav);
      }
    });
  });
  state.navList = menuList;
}), _defineProperty(_mutations, types.SET_SIDE_BAR_STATE, function (state, isShow) {
  state.isShow = isShow;
}), _defineProperty(_mutations, types.UPDATE_IS_COLLAPSE, function (state, isCollapse) {
  state.isCollapse = isCollapse;
}), _defineProperty(_mutations, types.RESET_SIDEBAR_STATE, function (state) {
  state.isShow = true;
}), _mutations);
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};