import request from '@/utils/request';
export default {
  list: function list(query) {
    return request({
      url: 'admin/productFirmware/v2/listPage',
      method: 'get',
      params: query
    });
  },
  add: function add(data) {
    return request({
      url: 'admin/productFirmware/v2/createOTAFirmware',
      method: 'post',
      data: data
    });
  },
  update: function update(data) {
    return request({
      url: 'admin/productFirmware/v2/update',
      method: 'post',
      data: data
    });
  },
  get: function get(id) {
    return request({
      url: "admin/productFirmware/v2/detail?id=".concat(id),
      method: 'get'
    });
  },
  del: function del(id) {
    return request({
      url: "admin/productFirmware/v2/delete?id=".concat(id),
      method: 'post'
    });
  },
  generateOTAUploadURL: function generateOTAUploadURL(data) {
    return request({
      url: 'admin/productFirmware/generateOTAUploadURL',
      method: 'post',
      data: data
    });
  },
  createOTAVerify: function createOTAVerify(data) {
    return request({
      url: 'admin/productOtaJob/v2/createOTAVerify',
      method: 'post',
      data: data
    });
  },
  syncOTAJob: function syncOTAJob(data) {
    return request({
      url: 'admin/productOtaJob/v2/syncOTAJob',
      method: 'post',
      data: data
    });
  },
  createOTAUpgradeJob: function createOTAUpgradeJob(data) {
    return request({
      url: 'admin/productOtaJob/v2/createOTAUpgradeJob',
      method: 'post',
      data: data
    });
  },
  firmwareCount: function firmwareCount(data) {
    return request({
      url: 'admin/report/device/firmwareCount',
      method: 'post',
      data: data
    });
  },
  statusCount: function statusCount(data) {
    return request({
      url: 'admin/productDeviceUpgrade/statusCount',
      method: 'post',
      data: data
    });
  },
  upgradeListPage: function upgradeListPage(data) {
    return request({
      url: 'admin/productDeviceUpgrade/listPage',
      method: 'post',
      data: data
    });
  },
  listFirmwareJob: function listFirmwareJob(data) {
    return request({
      url: 'admin/productOtaJob/listFirmwareJob',
      method: 'post',
      data: data
    });
  },
  cancelOTATask: function cancelOTATask(data) {
    return request({
      url: 'admin/productDeviceUpgrade/cancelOTATask',
      method: 'post',
      data: data
    });
  }
};