import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/apps/statistics'
}, {
  path: '/apps/content',
  component: Layout,
  redirect: '/apps/content/list',
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/content/index');
    },
    name: 'Content',
    meta: {
      title: '内容管理'
    }
  }]
}, {
  path: '/apps/push',
  component: Layout,
  redirect: '/apps/push/list',
  meta: {
    title: '推送管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/push/index');
    },
    name: 'Push'
  }]
}, {
  path: '/apps/petCategory',
  component: Layout,
  redirect: '/apps/petCategory/list',
  meta: {
    title: '宠物品种'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/petCategory/index');
    },
    name: 'PetCategory'
  }]
}, {
  path: '/apps/item',
  component: Layout,
  redirect: '/apps/item/list',
  meta: {
    title: '商品管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/item/index');
    },
    name: 'Item'
  }]
}, {
  path: '/apps/wxCs',
  component: Layout,
  redirect: '/apps/wxCs/list',
  meta: {
    title: '客服微信管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/wxCs/index');
    },
    name: 'wxCs'
  }]
}, {
  path: '/apps/about',
  component: Layout,
  redirect: '/apps/about/index',
  meta: {
    title: 'App关于我们'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/about/index');
    },
    name: 'about'
  }]
}, {
  path: '/apps/protocol',
  component: Layout,
  redirect: '/apps/protocol/index',
  meta: {
    title: 'App隐私协议'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/protocol/index');
    },
    name: 'protocol'
  }]
}, {
  path: '/apps/appVersion',
  component: Layout,
  redirect: '/apps/appVersion/index',
  meta: {
    title: 'App版本列表'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/appVersion/index');
    },
    name: 'appVersion'
  }]
}, {
  path: '/apps/product',
  component: Layout,
  redirect: '/apps/product/list',
  meta: {
    title: '产品管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/product/index');
    },
    name: 'Product'
  }]
}, {
  path: '/apps/device',
  component: Layout,
  redirect: '/apps/device/list',
  meta: {
    title: '设备管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/device/index');
    },
    name: 'Device'
  }]
}, {
  path: '/apps/error',
  component: Layout,
  redirect: '/apps/error/list',
  meta: {
    title: '故障管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/error/index');
    },
    name: 'Error'
  }]
}, {
  path: '/apps/ota',
  component: Layout,
  redirect: '/apps/ota/list',
  meta: {
    title: '产品升级管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/ota/index');
    },
    name: 'Ota'
  }, {
    path: 'detail',
    component: function component() {
      return import('@/views/ota/detail');
    },
    name: 'OtaDetail',
    meta: {
      title: '产品详情'
    }
  }]
}, {
  path: '/apps/userMoment',
  component: Layout,
  redirect: '/apps/userMoment/list',
  meta: {
    title: '用户内容管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/userMoment/index');
    },
    name: 'UserMoment'
  }]
}, {
  path: '/apps/user',
  component: Layout,
  redirect: '/apps/user/list',
  meta: {
    title: '用户管理'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/user/list');
    },
    name: 'User'
  }]
}, {
  path: '/apps/statistics',
  component: Layout,
  redirect: '/apps/statistics/index',
  meta: {
    title: '数据统计'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/statistics/index');
    },
    name: 'Statistics'
  }]
}, {
  path: '/apps/feedback',
  component: Layout,
  redirect: '/apps/feedback/list',
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/feedback/list');
    },
    name: 'feedback',
    meta: {
      title: '用户反馈'
    }
  }]
}, {
  path: '/apps/sysUser',
  component: Layout,
  redirect: '/apps/sysUser/list',
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/sysUser/list');
    },
    name: 'SysUser',
    meta: {
      title: '系统用户管理'
    }
  }]
}, {
  path: '/apps/role',
  component: Layout,
  redirect: '/apps/role/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/role/role');
    },
    name: 'Role',
    meta: {
      title: '角色管理'
    }
  }]
}, {
  path: '/apps/myInfo',
  component: Layout,
  redirect: '/apps/myInfo/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/myInfo');
    },
    name: 'MyInfo',
    meta: {
      title: '个人信息'
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;