export var SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export var RESET_CORP = 'RESET_CORP';
/**
 * 插件token
 */

export var UPDATE_APP_TOKEN = 'UPDATE_APP_TOKEN';
export var RESET_APP_TOKEN = 'RESET_APP_TOKEN';
export var APPLYING = 'APPLYING';
export var ADDLISTENER = 'ADDLISTENER';
export var CLEARTLISTENER = 'CLEARTLISTENER'; // 侧栏状态

export var SET_SIDE_BAR_STATE = 'SET_SIDE_BAR_STATE';
export var RESET_SIDEBAR_STATE = 'RESET_SIDEBAR_STATE';
export var SET_ACTIVE_NAV = 'SET_ACTIVE_NAV';
export var UPDATE_SIDE_NAV_LIST = 'UPDATE_SIDE_NAV_LIST';
export var UPDATE_ACCOUNT_NAV_LIST = 'UPDATE_ACCOUNT_NAV_LIST';
export var UPDATE_IS_COLLAPSE = 'UPDATE_IS_COLLAPSE'; // 面包屑状态

export var SET_PATH_LIST = 'SET_PATH_LIST';
export var CLEAR_PATH = 'CLEAR_PATH';
export var ADD_PATH = 'ADD_PATH';
export var REMOVE_PATH = 'REMOVE_PATH'; // 标签页状态

export var ADD_TAG = 'ADD_TAG';
export var REMOVE_TAG = 'REMOVE_TAG';