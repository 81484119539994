import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
import debounce from 'lodash.debounce';
import Vue from 'vue'; // enumerating ECharts events for now

var ACTION_EVENTS = ['legendselectchanged', 'legendselected', 'legendunselected', 'datazoom', 'datarangeselected', 'timelinechanged', 'timelineplaychanged', 'restore', 'dataviewchanged', 'magictypechanged', 'geoselectchanged', 'geoselected', 'geounselected', 'pieselectchanged', 'pieselected', 'pieunselected', 'mapselectchanged', 'mapselected', 'mapunselected', 'axisareaselected', 'focusnodeadjacency', 'unfocusnodeadjacency', 'brush', 'brushselected'];
var MOUSE_EVENTS = ['click', 'dblclick', 'mouseover', 'mouseout', 'mousedown', 'mouseup', 'globalout'];
export default {
  props: {
    options: Object,
    theme: [String, Object],
    initOptions: Object,
    group: String,
    autoResize: Boolean,
    watchShallow: Boolean
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    // Only recalculated when accessed from JavaScript.
    // Won't update DOM on value change because getters
    // don't depend on reactive values
    width: {
      cache: false,
      get: function get() {
        return this.delegateGet('width', 'getWidth');
      }
    },
    height: {
      cache: false,
      get: function get() {
        return this.delegateGet('height', 'getHeight');
      }
    },
    isDisposed: {
      cache: false,
      get: function get() {
        return !!this.delegateGet('isDisposed', 'isDisposed');
      }
    },
    computedOptions: {
      cache: false,
      get: function get() {
        return this.delegateGet('computedOptions', 'getOption');
      }
    }
  },
  watch: {
    group: function group(_group) {
      this.chart.group = _group;
    }
  },
  methods: {
    // provide a explicit merge option method
    mergeOptions: function mergeOptions(options, notMerge, lazyUpdate) {
      this.delegateMethod('setOption', options, notMerge, lazyUpdate);
    },
    // just delegates ECharts methods to Vue component
    // use explicit params to reduce transpiled size for now
    resize: function resize(options) {
      this.delegateMethod('resize', options);
    },
    dispatchAction: function dispatchAction(payload) {
      this.delegateMethod('dispatchAction', payload);
    },
    convertToPixel: function convertToPixel(finder, value) {
      return this.delegateMethod('convertToPixel', finder, value);
    },
    convertFromPixel: function convertFromPixel(finder, value) {
      return this.delegateMethod('convertFromPixel', finder, value);
    },
    containPixel: function containPixel(finder, value) {
      return this.delegateMethod('containPixel', finder, value);
    },
    showLoading: function showLoading(type, options) {
      this.delegateMethod('showLoading', type, options);
    },
    hideLoading: function hideLoading() {
      this.delegateMethod('hideLoading');
    },
    getDataURL: function getDataURL(options) {
      return this.delegateMethod('getDataURL', options);
    },
    getConnectedDataURL: function getConnectedDataURL(options) {
      return this.delegateMethod('getConnectedDataURL', options);
    },
    clear: function clear() {
      this.delegateMethod('clear');
    },
    dispose: function dispose() {
      this.delegateMethod('dispose');
    },
    delegateMethod: function delegateMethod(name) {
      var _this$chart;

      if (!this.chart) {
        Vue.util.warn("Cannot call [".concat(name, "] before the chart is initialized. Set prop [options] first."), this);
        return;
      }

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return (_this$chart = this.chart)[name].apply(_this$chart, args);
    },
    delegateGet: function delegateGet(name, method) {
      if (!this.chart) {
        Vue.util.warn("Cannot get [".concat(name, "] before the chart is initialized. Set prop [options] first."), this);
      }

      return this.chart[method]();
    },
    init: function init() {
      var _this = this;

      if (this.chart) {
        return;
      }

      var chart = echarts.init(this.$el, this.theme, this.initOptions);

      if (this.group) {
        chart.group = this.group;
      }

      chart.setOption(this.options, true); // expose ECharts events as custom events

      ACTION_EVENTS.forEach(function (event) {
        chart.on(event, function (params) {
          _this.$emit(event, params);
        });
      });
      MOUSE_EVENTS.forEach(function (event) {
        chart.on(event, function (params) {
          _this.$emit(event, params); // for backward compatibility, may remove in the future


          _this.$emit('chart' + event, params);
        });
      });

      if (this.autoResize) {
        this.__resizeHanlder = debounce(function () {
          chart.resize();
        }, 100, {
          leading: true
        });
        window.addEventListener('resize', this.__resizeHanlder);
      }

      this.chart = chart;
    },
    destroy: function destroy() {
      if (this.autoResize) {
        window.removeEventListener('resize', this.__resizeHanlder);
      }

      this.dispose();
      this.chart = null;
    },
    refresh: function refresh() {
      this.destroy();
      this.init();
    }
  },
  created: function created() {
    var _this2 = this;

    this.$watch('options', function (options) {
      if (!_this2.chart && options) {
        _this2.init();
      } else {
        _this2.chart.setOption(_this2.options, true);
      }
    }, {
      deep: !this.watchShallow
    });
    var watched = ['theme', 'initOptions', 'autoResize', 'watchShallow'];
    watched.forEach(function (prop) {
      _this2.$watch(prop, function () {
        _this2.refresh();
      }, {
        deep: true
      });
    });
  },
  mounted: function mounted() {
    // auto init if `options` is already provided
    if (this.options) {
      this.init();
    }
  },
  activated: function activated() {
    if (this.autoResize) {
      this.chart && this.chart.resize();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.destroy();
  },
  connect: function connect(group) {
    if (typeof group !== 'string') {
      group = group.map(function (chart) {
        return chart.chart;
      });
    }

    echarts.connect(group);
  },
  disconnect: function disconnect(group) {
    echarts.disConnect(group);
  },
  registerMap: function registerMap() {
    var _echarts;

    (_echarts = echarts).registerMap.apply(_echarts, arguments);
  },
  registerTheme: function registerTheme() {
    var _echarts2;

    (_echarts2 = echarts).registerTheme.apply(_echarts2, arguments);
  }
};