var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "sidebar",
      class: { "has-logo": _vm.showLogo }
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              staticClass: "side-menu-list",
              style: { minHeight: _vm.contentHeight },
              attrs: {
                collapse: _vm.isCollapse,
                "default-active": _vm.activeIndex,
                router: true,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical"
              }
            },
            [
              _vm._l(_vm.navList, function(nav) {
                return [
                  !nav.children.length
                    ? [
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: nav.menuUrl },
                            on: {
                              click: function($event) {
                                return _vm.menuClick(nav)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont",
                              class: nav.icon
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(nav.menuName))]
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "el-submenu",
                          { attrs: { index: nav.menuUrl } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("i", {
                                staticClass: "iconfont",
                                class: nav.icon
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(nav.menuName))]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(nav.children, function(subMenu, subIndex) {
                              return _c(
                                "el-menu-item",
                                {
                                  key: subIndex,
                                  attrs: { index: subMenu.menuUrl },
                                  on: {
                                    click: function($event) {
                                      return _vm.menuClick(subMenu)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(subMenu.menuName))]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }