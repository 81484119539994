var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            content: _vm.isFullscreen ? "退出全屏" : "全屏",
            effect: "dark",
            placement: "bottom"
          }
        },
        [
          _c("svg-icon", {
            attrs: {
              "icon-class": _vm.isFullscreen ? "exit-fullscreen" : "fullscreen"
            },
            on: { click: _vm.click }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }