import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }

  return time + label + 's';
}
/**
 * @param {number} time
 */


export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);

  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}
/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */

export function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];

  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }

  return num.toString();
}
/**
 * 10000 => "10,000"
 * @param {number} num
 */

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}
/**
 * Upper case first char
 * @param {String} string
 */

export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function sex(type) {
  switch (type) {
    case 0:
      return '女';

    case 1:
      return '男';

    case 2:
      return '不限';
  }
}
export function money(value) {
  if (!value) {
    return '0';
  }

  if (value % 100 == 0) {
    return value / 100;
  }

  var fixedValue = parseFloat(value / 100).toFixed(2) + '';
  return fixedValue.replace(/0+$/g, '');
}