import _objectSpread from "/Users/wangjianbing/work/granwin/pet-admin-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from 'vuex';
import { clearUserCache } from '@/api/sys';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  mounted: function mounted() {
    clearUserCache();
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};