import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/Users/wangjianbing/work/granwin/pet-admin-web/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/wangjianbing/work/granwin/pet-admin-web/node_modules/core-js/modules/es6.promise.js";
import "/Users/wangjianbing/work/granwin/pet-admin-web/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/wangjianbing/work/granwin/pet-admin-web/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import '@/styles/page.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import ElementUICustom from "./element-ui-custom";
import charts from '@/components/eCharts';
import * as filters from "./filters"; // global filters

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size

});
Vue.use(ElementUICustom); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.component('chart', charts);
/**
 * 注册全局
 */

Vue.mixin({
  data: function data() {
    return {
      ENV: ENV,
      TT: TT,
      timeList: [{
        title: '最近7天',
        value: 7
      }, {
        title: '最近30天',
        value: 30
      }, {
        title: '自定义',
        value: 0
      }]
    };
  }
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});