//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    title: function title() {
      return this.$store.state.settings.title;
    }
  }
};