import "core-js/modules/es6.regexp.replace";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import Qs from 'qs';
import router from '@/router/index'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  },
  transformRequest: [function (data) {
    return Qs.stringify(data, {
      arrayFormat: 'brackets'
    });
  }],
  timeout: 10000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  if (/application\/json/.test(config.headers['Content-Type'])) {
    config.data = JSON.stringify(config.data);
    delete config.transformRequest;
  }

  config.headers['x-admin-token'] = getToken();
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  if (res.code === 401) {
    store.dispatch('user/resetToken').then(function () {
      router.replace('/login');
    });
  } // if (res.code === 200) {


  return res; // }
}, function (error) {
  console.log(error.message); // for debug

  if (error.response.data.code === 401) {
    store.dispatch('user/resetToken').then(function () {
      router.replace('/login');
    });
  }

  return Promise.resolve({
    code: 500,
    msg: error.response.data.msg
  });
});
export default service;